import React from 'react';
import { useNavigate } from 'react-router-dom';
import { GetGoogleAuth } from '../../common/common';

import Button from '../ui/Button';
import Header from '../ui/Header';
import OriginalAvatarCard from '../ui/OriginalAvatarCard';

import { axiosClient } from '../../utilities/AxiosConfig';
import { ResponseType as oalr } from '../../common/types/OriginalAvatarListResponse';
import { ResponseType as oacr } from '../../common/types/OriginalAvatarCountResponse';
import { item } from '../../common/types/OriginalAvatarListResponse';

let count = 0;
let nextDate = false;
let httpFlag = true;
let localOriginalAvatarList: item[] = [];

async function getOriginalAvatarList(userId: number, offset: number): Promise<oalr> {
  try {
    // URL
    const response = await axiosClient.get(
      '/user/originalAvatar/list?offset=' +
      offset * Number(process.env.REACT_APP_ORIGINAL_AVATAR_LIST_COUNT!),
      {
        headers: {
          userid: userId,
          logintype: '2',
        },
      },
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return { originalAvatarList: [] };
  }
}

async function getOriginalAvatarCount(userId: number): Promise<oacr> {
  try {
    const response = await axiosClient.get('/user/originalAvatar/CreationCount', {
      headers: {
        userid: userId,
        logintype: '2',
      },
    });
    // 各プロンプトリストにisEnableを追加
    console.log(response.data['countCreatableOriginalAvatar']);
    return {
      countCreatableOriginalAvatar: response.data['countCreatableOriginalAvatar'],
    };
  } catch (error) {
    console.log(error);
    return {
      countCreatableOriginalAvatar: 0,
    };
  }
}

const OriginalAvatarList = () => {
  const navigate = useNavigate();
  const info = GetGoogleAuth();

  const [, setOriginalAvatarList] = React.useState<item[]>([]);
  const [stateOACreate, setOACreate] = React.useState<boolean>(true);

  const isRunning = React.useRef(false); // スクロール多発防止用フラグ
  const isScrollToggle = React.useCallback(() => {
    if (isRunning.current) return;
    isRunning.current = true;
    const bottomPosition = document.body.offsetHeight - (window.scrollY + window.innerHeight);

    if (bottomPosition < 1) {
      if (nextDate) {
        // 次のデータがないので取得しない
        console.log('次のデータがないので取得しない');
        return;
      } else {
        if (httpFlag) {
          // 通信していないので通信
          httpFlag = false;
          (async () => {
            count = count + 1;
            const originalAvatarList = await getOriginalAvatarList(info.userId, count);
            localOriginalAvatarList = localOriginalAvatarList.concat(
              originalAvatarList.originalAvatarList,
            );
            setOriginalAvatarList(localOriginalAvatarList);
            if (
              originalAvatarList.originalAvatarList.length <
              Number(process.env.REACT_APP_ORIGINAL_AVATAR_LIST_COUNT!)
            ) {
              // 最後のデータを取得
              nextDate = true;
            }
            httpFlag = true;
          })();
        }
      }
    }
    isRunning.current = false;
  }, [info.userId]);

  React.useEffect(() => {
    count = 0;
    nextDate = false;
    document.addEventListener('scroll', isScrollToggle);
    (async () => {
      const goac = await getOriginalAvatarCount(info.userId);
      console.log(goac.countCreatableOriginalAvatar);
      if (goac.countCreatableOriginalAvatar > 0) {
        console.log('作成ボタン:押せる');
        setOACreate(false);
      } else {
        console.log('作成ボタン:押せない');
        setOACreate(true);
      }

      const originalAvatarList = await getOriginalAvatarList(info.userId, 0);
      localOriginalAvatarList = originalAvatarList.originalAvatarList;
      setOriginalAvatarList(localOriginalAvatarList);
      if (
        localOriginalAvatarList.length <
        Number(process.env.REACT_APP_ORIGINAL_AVATAR_LIST_COUNT!)
      ) {
        // 最後のデータを取得
        console.log('最後のデータを取得');
        nextDate = true;
      }
      console.log('取得アバター(初回) : ');
      console.log(localOriginalAvatarList);
    })();
    return () => {
      document.removeEventListener('scroll', isScrollToggle);
    };
  }, []);

  return (
    <div className="bg-baseColor">
      <Header title={'アバターメーカー'} backFlag={true} />
      <div className="pt-[56px]">
        <div className="mx-4 mt-6 mb-2">
          {/* <Title title={'オリジナルアバター一覧'} /> */}
          <p className="text-left mb-2 font-sans">
            <b className="text-textColor text-sm">オリジナルアバター一覧</b>
            <Button
              text="＋ アバター作成"
              margin={'ml-6'}
              onClick={() => {
                document.removeEventListener('scroll', isScrollToggle);
                navigate('/CreateAvatar');
              }}
              isDisable={stateOACreate}
            />
          </p>
          <p className="text-left mb-4 text-textColor text-[0.5rem] whitespace-pre-wrap">
            1店舗に初コメントを投稿すると1回アバターを作成することができます。
          </p>
        </div>
        <OriginalAvatarCard list={localOriginalAvatarList} />
      </div>
      <div className="pb-[56px]" />
    </div>
  );
};
export default OriginalAvatarList;
