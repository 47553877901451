import React from 'react';

import Header from '../ui/Header';
import AvatarPromptList from '../ui/AvatarPromptList';
import Silhouette from '../../images/Silhouette.png';

import { GoogleAuth } from '../../store';
import { useTitleIdStore } from '../../originalAvatarIdStore';
import { axiosClient } from '../../utilities/AxiosConfig';
import { GetGoogleAuth } from '../../common/common';
import { ResponseType as oadr } from '../../common/types/OriginalAvatarDetailResponse';

async function getOriginalAvatarDetail(userInfo: GoogleAuth, titleId: number): Promise<oadr> {
  try {
    const response = await axiosClient.get(
      '/user/originalAvatar/detail?titleId=' + String(titleId),
      {
        headers: {
          userid: userInfo.userId,
          logintype: '2',
        },
      },
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      titleName: '',
      titlePicture: Silhouette,
      selectPrompt: [],
      createdTime: '',
    };
  }
}
const OriginalAvatarDetail = () => {
  const userInfo = GetGoogleAuth();
  const [originalAvatarDetailData, setOriginalAvatarDetailData] = React.useState<oadr>({
    titleName: '',
    titlePicture: '',
    selectPrompt: [],
    createdTime: '',
  });

  const [storeTitleId] = useTitleIdStore((state) => [state.titleId]);
  const titleId = storeTitleId === undefined ? 0 : storeTitleId;
  console.log(storeTitleId);
  React.useEffect(() => {
    (async () => {
      const originalAvatarDetail = await getOriginalAvatarDetail(userInfo, titleId);
      setOriginalAvatarDetailData(originalAvatarDetail);
    })();
    // ESLintのWARNING無効
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="bg-baseColor w-screen h-screen">
      <Header title={'アバターメーカー'} backFlag={true} />
      <div className="pt-[56px]">
        <div className="mt-6 mb-1 text-base text-textColor font-bold flex justify-center">
          {originalAvatarDetailData.titleName}
        </div>

        <img
          className="rounded-full mt-3 mb-5 mx-auto border-[10px] border-avatarBorderColor"
          src={originalAvatarDetailData.titlePicture}
          alt="logo"
          width="260"
          height="260"
        />
        <div className="mt-6 mb-1 text-base text-textColor font-bold flex justify-center">
          使用した言葉
        </div>
        <div className="w-[240px] mx-auto">
          <AvatarPromptList list={originalAvatarDetailData.selectPrompt} />
        </div>
        <div className="mt-6 mb-1 text-base text-textColor font-bold flex justify-center">
          作成日時
        </div>
        <div className="mt-4 text-textColor flex justify-center">
          {originalAvatarDetailData.createdTime}
        </div>
      </div>
    </div>
  );
};

export default OriginalAvatarDetail;
