import React from 'react';
import Silhouette from '../../images/Silhouette.png';

const Icon: React.FC<{
  type: number;
  titlePicture: string;
  medalCount: number;
  medalList: number[];
}> = ({ type, titlePicture, medalCount, medalList }) => {
  return (
    <div className="mx-4">
      <img
        className={type === 9 ? 'rounded-full mx-auto mt-1' : 'rounded-full mx-auto mt-1 mb-2'}
        src={medalCount !== 0 && medalCount === medalList.length ? titlePicture : Silhouette}
        alt="logo"
        width="64"
        height="64"
      />
    </div>
  );
};

export default Icon;
