import lungle from '../../images/Lungle.png';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../../auth/authFirebase';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-baseColor w-screen h-screen">
      <img className="mx-auto pt-20 mb-2.5" src={lungle} alt="logo" width="144" height="144" />
      <p className="text-center my-8 font-sans">
        <b className="text-textColor text-sm">
          エラーが発生しました。
          <br />
          以下のボタンでTOP画面に戻り、再ログインをしてください。
        </b>
      </p>
      <button
        className="bg-cardColor text-center text-white rounded px-6 py-1"
        type="button"
        onClick={async () => {
          await signOut(auth)
          navigate('/');
        }}
      >
        TOP画面へ
      </button>
    </div>
  );
};

export default NotFound;
