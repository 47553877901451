import React from 'react';
import { item } from '../../common/types/TitleListResponse';
import Icon from './Icon';
import { useNavigate } from 'react-router-dom';

import { useTitleNameStore } from '../../titleNameStore';

const NormalCard: React.FC<{ list: Array<item> }> = ({ list }) => {
  const navigate = useNavigate();
  const [setTitleName] = useTitleNameStore((state) => [state.setTitleName]);

  return (
    <div className="mx-4">
      <div className="grid grid-cols-3 gap-2">
        {list.map((choice: item, index) => (
          <div
            key={index}
            className="bg-cardColor text-center text-white rounded"
            onClick={() => {
              setTitleName(choice.titleName);
              navigate('/TitleDetail');
            }}
          >
            <div className="text-[#4D3D32] mt-2 mx-1 text-xs">
              <b>{choice.titleName}</b>
            </div>
            <div className="text-[#4D3D32] mx-1 text-[0.5rem]">{choice.genreName}</div>
            <Icon
              type={1}
              titlePicture={choice.titlePicture}
              medalCount={choice.medalCount}
              medalList={choice.medalList}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default NormalCard;
