import React from 'react';
import ArrowRight from '../../images/ArrowRight.png';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../../auth/authFirebase';
import { useSWRConfig } from 'swr';

const Menu: React.FC<{}> = (props) => {
  const navigate = useNavigate();
  const { cache } = useSWRConfig();

  const onClickLungle = () => {
    console.log('onClickLungle');
    window.open(process.env.REACT_APP_ABOUT_LUNGLE!, '_blank');
  };

  const onClickTermsOfService = () => {
    console.log('onClickTermsOfService');
    window.open(process.env.REACT_APP_TERMS_OF_SERBVICE!, '_blank');
  };

  const onClickPrivacyPolicy = () => {
    console.log('onClickPrivacyPolicy');
    window.open(process.env.REACT_APP_PRIVACY_POLICY!, '_blank');
  };

  const onClickCookieWebStorage = () => {
    console.log('onClickCookieWebStorage');
    window.open(process.env.REACT_APP_COOKIE_WEBSTORAGE!, '_blank');
  };

  const onClickLogout = async () => {
    try {
      console.log('onClickLogout');
      await signOut(auth).then(() => {
        console.log('ログアウトしました');
        cache.delete('idToken');
        navigate('/');
      });
    } catch {
      console.log("ログアウトに失敗しました");
    }
  };

  return (
    <div className="bg-black bg-opacity-20 top-[56px] h-full w-full fixed">
      <div className="bg-[#FFFFFF] top-[45px] fixed right-2 rounded w-[200px] shadow-boxShadow">
        <div>
          <ul>
            <li className="py-4">
              <div
                onClick={() => {
                  onClickLungle();
                }}
              >
                <ul className="flex">
                  <div className="px-4 text-left text-sm">Lungleについて</div>
                  <img
                    className="mt-auto mb-auto ml-auto mr-4"
                    src={ArrowRight}
                    alt="back"
                    width="6"
                    height="8"
                  />
                </ul>
              </div>
            </li>
            <li className="py-4">
              <div
                onClick={() => {
                  onClickTermsOfService();
                }}
              >
                <ul className="flex">
                  <div className="px-4 text-left text-sm">利用規約</div>
                  <img
                    className="mt-auto mb-auto ml-auto mr-4"
                    src={ArrowRight}
                    alt="back"
                    width="6"
                    height="8"
                  />
                </ul>
              </div>
            </li>
            <li className="py-4">
              <div
                onClick={() => {
                  onClickPrivacyPolicy();
                }}
              >
                <ul className="flex">
                  <div className="px-4 text-left text-sm">プライバシーポリシー</div>
                  <img
                    className="mt-auto mb-auto ml-auto mr-4"
                    src={ArrowRight}
                    alt="back"
                    width="6"
                    height="8"
                  />
                </ul>
              </div>
            </li>
            <li className="py-4">
              <div
                onClick={() => {
                  onClickCookieWebStorage();
                }}
              >
                <ul className="flex">
                  <div className="px-4 text-left text-sm">Cookie・WebStorageの使用について</div>
                  <img
                    className="mt-auto mb-auto ml-auto mr-4"
                    src={ArrowRight}
                    alt="back"
                    width="6"
                    height="8"
                  />
                </ul>
              </div>
            </li>
            <li className="py-4">
              <div
                onClick={() => {
                  onClickLogout();
                }}
              >
                <ul className="flex">
                  <div className="px-4 text-left text-sm">ログアウト</div>
                  <img
                    className="mt-auto mb-auto ml-auto mr-4"
                    src={ArrowRight}
                    alt="back"
                    width="6"
                    height="8"
                  />
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Menu;
