import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import { getRemoteConfig } from 'firebase/remote-config';
import { getFirestore } from 'firebase/firestore';

// Initialize Firebase
const firebaseApp = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY!,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN!,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID!,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET!,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID!,
    appId: process.env.REACT_APP_FIREBASE_APP_ID!,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID!,
});
export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
export const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
  prompt: 'select_account',
});
export const appleProvider = new OAuthProvider('apple.com');
appleProvider.setCustomParameters({
  prompt: 'select_account',
});
export const remoteConfig = getRemoteConfig(firebaseApp);
remoteConfig.settings.minimumFetchIntervalMillis = 60;
remoteConfig.defaultConfig = {
  maintenance_info_dev: '',
};
