import React, { useEffect, useState } from 'react';
// import { useErrorBoundary } from 'react-error-boundary';

import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

import { useAuthenticated } from '../hooks/useAuth';
import loading_gif from '../images/animation_loading.gif';
import { useNavigate } from 'react-router-dom';

export const axiosClient = axios.create({
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
  baseURL: process.env.REACT_APP_API_ENDPOINT!,
});

type Props = {
  children: React.ReactNode;
};

export const AxiosConfig = (props: Props) => {
  const { children } = props;

  const { idToken } = useAuthenticated();
	const [isTokenSet, setIsTokenSet] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const requestInterceptor = axiosClient.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        config.headers.Authorization = `${idToken}`;
        return config;
      },
      (error: AxiosError) => console.log(error),
    );
    const responseInterceptor = axiosClient.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      (error: AxiosError) => {
        switch (error.response?.status) {
          case 400:
          case 401:
            // エラーページへ
            navigate('/error');
            break;
          default:
            break;
        }
				return Promise.reject(error);
      },
    );
		if (idToken) setIsTokenSet(true);
    
		return () => {
      axiosClient.interceptors.response.eject(responseInterceptor);
      axiosClient.interceptors.request.eject(requestInterceptor);
    };
  }, [idToken]);

  if (!isTokenSet) return <div className="fixed top-0 left-0 w-screen h-screen"><div className="fixed bg-baseColor opacity-80 h-screen w-screen flex justify-center items-center"><img src={loading_gif} alt="ローディング" width="150" height="150" /></div></div>;

  return <>{children}</>;
};
