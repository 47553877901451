import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

interface TitleNameState {
  titleName: undefined | string;
  initializeTitleName: () => void;
  setTitleName: (titleName: string) => void;
}

export const useTitleNameStore = create<TitleNameState>()(
  persist(
    (set) => ({
      titleName: undefined,
      setTitleName: (titleName: string) => {
        set({ titleName: titleName });
      },

      initializeTitleName: () => {
        set({ titleName: undefined });
      },
    }),
    {
      name: 'titleName',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
