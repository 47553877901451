import React from 'react';
import './App.css';
import Top from './components/page/Top';
import Title from './components/page//Title';
import TitleList from './components/page/TitleList';
import TitleDetail from './components/page/TitleDetail';
import ComponentWrapper from './components/ComponentWrapper';
import NotFound from './components/page/NotFound';
import OriginalAvatarList from './components/page/OriginalAvatarList';
import OriginalAvatarDetail from './components/page/OriginalAvatarDetail';
import CreateAvatar from './components/page/CreateAvatar';
import SaveAvatar from './components/page/SaveAvatar';
import Maintenance from './components/page/Maintenance';


import ParentLayout from './components/page/layout/ParentLayout'

import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <ComponentWrapper>
                <Top />
              </ComponentWrapper>
            }
          />
          <Route
            path="/Title"
            element={
              <ParentLayout>
                <Title />
              </ParentLayout>
            }
          />
          <Route
            path="/TitleList"
            element={
              <ParentLayout>
                <TitleList />
              </ParentLayout>
            }
          />
          <Route
            path="/TitleDetail"
            element={
              <ParentLayout>
                <TitleDetail />
              </ParentLayout>
            }
          />
          <Route
            path="/OriginalAvatarList"
            element={
              <ParentLayout>
                <OriginalAvatarList />
              </ParentLayout>
            }
          />
          <Route
            path="/OriginalAvatarDetail"
            element={
              <ParentLayout>
                <OriginalAvatarDetail />
              </ParentLayout>
            }
          />
          <Route
            path="/CreateAvatar"
            element={
              <ParentLayout>
                <CreateAvatar />
              </ParentLayout>
            }
          />
          <Route
            path="/SaveAvatar"
            element={
              <ParentLayout>
                <SaveAvatar />
              </ParentLayout>
            }
          />
          <Route path="/Maintenance" element={<Maintenance />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
