import React from 'react';
import lungle from '../../images/Lungle.png';
import google from '../../images/google.png';
import apple from '../../images/Apple.png';
import Loading from '../ui/LoadingView';
import { useNavigate } from 'react-router-dom';
import { signInWithPopup } from 'firebase/auth';
import { auth, googleProvider, appleProvider } from '../../auth/authFirebase';
import { useAuthStore, selectProvider } from '../../store';

import axios from 'axios';
import { ResponseType } from '../../common/types/AuthLogin';
import { GetGoogleAuth } from '../../common/common';

async function getAuthlogin(oauthIdToken: string): Promise<ResponseType> {
  try {
    const url = process.env.REACT_APP_API_ENDPOINT! + 'auth/login';
    const response = await axios.get<ResponseType>(url, {
      headers: {
        Authorization: oauthIdToken,
        logintype: '2',
      },
    });
    return {
      result: response.data.result,
      isRegistered: response.data.isRegistered,
      userId: response.data.userId,
      selectAreaId: response.data.selectAreaId,
      areaList: response.data.areaList,
    };
  } catch (error) {
    console.error(error);
    return {
      result: false,
      isRegistered: false,
      userId: 0,
      selectAreaId: 0,
      areaList: [],
    };
  }
}

const Top = () => {
  const navigate = useNavigate();
  const [setGoogleAuth] = useAuthStore((state) => [state.setGoogleAuth]);
  const [logout] = useAuthStore((state) => [state.logout]);
  const [visible, setVisible] = React.useState<boolean>(false);

  // 作成時(初回レンダリング)
  React.useEffect(() => {
    console.log('TOP useEffect');
    logout();
  },[logout]);

  // ログイン処理
  const firebaseLogin = (apiResult: ResponseType, id: any, selectProvider: selectProvider) => {
    try {
      console.log('通信完了');
      // ローディング画面の削除
      setVisible(false);
      if (!apiResult.isRegistered) {
        alert('認証に失敗しました。\nアプリ版で登録を行ってください。');
      } else {
        setGoogleAuth(id, apiResult.userId, selectProvider);
        navigate('/Title');
      }
    } catch (err: any) {
      alert(err.message);
    }
  };

  const GoogleLogin = async () => {
    try {
      // ローディング画面呼び出し
      setVisible(true);
      console.log('Google認証');
      const authlogin = await signInWithPopup(auth, googleProvider);
      const id = await authlogin.user.getIdToken(true).then((idToken: any) => {
        return idToken;
      });
      const apiResult: ResponseType = await getAuthlogin(id);
      firebaseLogin(apiResult, id, 1);
    } catch (err: any) {
      setVisible(false);
      alert(err.message);
    }
  };

  const AppleLogin = async () => {
    try {
      // ローディング画面呼び出し
      setVisible(true);
      console.log('Apple認証');
      const authlogin = await signInWithPopup(auth, appleProvider);
      const id = await authlogin.user.getIdToken(true).then((idToken: any) => {
        return idToken;
      });
      const apiResult: ResponseType = await getAuthlogin(id);
      firebaseLogin(apiResult, id, 2);
    } catch (err: any) {
      setVisible(false);
      alert(err.message);
    }
  };

  const info = GetGoogleAuth();
  console.log('TOP画面');
  console.log(info);

  return (
    <div className="bg-baseColor w-screen h-screen">
      <img className="mx-auto pt-20 mb-2.5" src={lungle} alt="logo" width="144" height="144" />
      <p className="text-textColor text-xs whitespace-pre-wrap">社員みんなのランチ情報シェアアプリ</p>
      <div className="mt-[52px] grid grid-cols-1 justify-items-center">
        <button className="my-2 w-44" type="button" onClick={GoogleLogin}>
          <img className="mx-auto" src={google} alt="google" />
        </button>
        <button className="my-2 w-44" type="button" onClick={AppleLogin}>
          <img className="mx-auto" src={apple} alt="Apple" />
        </button>
      </div>
      <Loading visible={visible} />
    </div>
  );
};

export default Top;
