import React from 'react';

import Header from '../ui/Header';
import AvatarPromptList from '../ui/AvatarPromptList';
import Button from '../ui/Button';
import Loading from '../ui/LoadingView';

import { item as aplri } from '../../common/types/AvatarPromptListResponse';
import { useNavigate } from 'react-router-dom';

import { GoogleAuth } from '../../store';
import { GetGoogleAuth } from '../../common/common';

import { createOriginalAvatarListStore } from '../../createOriginalAvatarListStore';
import { item } from '../../common/types/CreateOriginalAvatarListResponse';
import { ResponseType as oarr } from '../../common/types/OriginalAvatarRegisterResponse';

import { axiosClient } from '../../utilities/AxiosConfig';

async function originalAvatarRegister(
  userInfo: GoogleAuth,
  avatarName: string,
  selectedAvatarPicture: number,
  avatarPromptList: aplri[],
  createOriginalAvatarList: item[],
): Promise<boolean> {
  try {
    console.log('CreateAvatar getOriginalAvatar');
    console.log(userInfo);
    console.log(avatarName);
    console.log(selectedAvatarPicture);
    console.log(avatarPromptList);
    console.log(createOriginalAvatarList[selectedAvatarPicture]);

    const originalAvatar: item = createOriginalAvatarList[selectedAvatarPicture];
    const selectPrompt = Object.values(avatarPromptList).map((values) => {
      return values.prompt;
    });
    const response = await axiosClient.post<oarr>(
      '/user/originalAvatar/register',
      {
        titleName: avatarName,
        titlePicture: originalAvatar.image,
        selectPrompt: selectPrompt.join(),
        positivePrompt: originalAvatar.prompt,
        negativePrompt: originalAvatar.negativePrompt,
        seed: originalAvatar.seed,
      },
      {
        headers: {
          userid: userInfo.userId,
          logintype: '2',
        },
        timeout: 30000,
      },
    );
    console.log(response.data.result);

    return response.data.result;
  } catch (error) {
    console.error(error);
    return false;
  }
}

const SaveAvatar = () => {
  const navigate = useNavigate();
  const userInfo = GetGoogleAuth();
  const AVATAR_NAME_MAX_LENGTH = 26;

  const [createOriginalAvatarList] = createOriginalAvatarListStore((state) => [
    state.createOriginalAvatarList,
  ]);
  const [createOriginalAvatarRequest] = createOriginalAvatarListStore((state) => [
    state.createOriginalAvatarRequest,
  ]);
  // 選択されたプロンプト一覧
  const [avatarPromptList, setAvatarPromptList] = React.useState<aplri[]>([
    { prompt: '', isEnable: false },
  ]);
  const [avatarPictureList, setAvatarPictureList] = React.useState<string[]>(['', '', '']);
  const [selectedAvatarPicture, setSelectedAvatarPicture] = React.useState<number>(0);
  const [avatarName, setAvatarName] = React.useState<string>('');
  const [isVisibleLoading, setIsVisibleLoading] = React.useState<boolean>(false);

  // アバター選択時
  const onAvatarPictureChange = (e: any) => {
    setSelectedAvatarPicture(e.currentTarget.id);
  };
  // アバター名変更時
  const onAvatarNameChange = (e: any) => {
    const newAvatarName = e.currentTarget.value;
    if (getAvatarNameLength(newAvatarName) <= AVATAR_NAME_MAX_LENGTH) {
      setAvatarName(newAvatarName);
    }
  };

  // アバター名の文字数
  const getAvatarNameLength = (avatarName: string) => {
    let nameLength: number = 0;

    for (let i = 0; i < avatarName.length; i++) {
      nameLength += avatarName[i].match('[ -~]') ? 1 : 2;
    }
    return nameLength;
  };

  // 保存ボタン押下時
  const onSaveClick = async () => {
    if (avatarName === '') {
      return;
    }
    setIsVisibleLoading(true);
    const result = await originalAvatarRegister(
      userInfo, // ユーザー情報
      avatarName, // オリジナルアバター名
      selectedAvatarPicture, // 選択したアバター情報
      avatarPromptList,
      createOriginalAvatarList,
    );

    if (result) {
      navigate(-2);
    }
    setIsVisibleLoading(false);
  };

  // 保存せずに破棄押下時
  const onCancelClick = () => {
    navigate(-2);
  };

  React.useEffect(() => {
    const avatarList = createOriginalAvatarList?.map((item: item) => {
      return item.image;
    });

    // 選択されたプロントを配置
    if (typeof createOriginalAvatarRequest === 'undefined') {
      alert('必要なデータが取得されていないのでTOP画面から実行してください');
      navigate('/');
    } else {
      const promptList: aplri[] = [];
      for (const value of Object.values(createOriginalAvatarRequest)) {
        promptList.push({
          prompt: value,
          isEnable: true,
        });
      }
      setAvatarPromptList(promptList);
    }
    // 作成された画像を追加
    if (avatarList?.length === 0) {
      alert('必要なデータが取得されていないのでTOP画面から実行してください');
      navigate('/');
    } else {
      setAvatarPictureList(avatarList!);
    }
  }, [createOriginalAvatarList, createOriginalAvatarRequest]);

  return (
    <div className="bg-baseColor w-screen h-screen">
      <Header title={'アバターメーカー'} backFlag={false} />
      <div className="pt-[56px]">
        <div className="mx-4 mt-6 mb-2">
          <p className="text-left mb-2 font-sans">
            <b className="text-textColor text-sm">オリジナルアバター作成</b>
          </p>
          <div className="text-center text-textColor text-xs mb-8">
            <p className="mb-2">
              <b>下記の言葉で、アバターを作成しました</b>
            </p>
            <div className="mx-12 mb-8">
              <AvatarPromptList list={avatarPromptList} />
            </div>
            <p className="mb-4">
              <b>作成された画像から１つ選んでください。</b>
            </p>
            <div className="grid grid-cols-3 gap-2 mx-8 mb-8">
              {avatarPictureList.map((avatarPicture, index) => (
                <label htmlFor={String(index)} key={index}>
                  <img
                    className="rounded-full mx-auto mt-1 mb-2"
                    src={'data:image/png;base64,' + avatarPicture}
                    alt="logo"
                    width="64"
                    height="64"
                  />
                  <input
                    type="radio"
                    key={index}
                    id={String(index)}
                    name="avatar-picture"
                    value={index}
                    onClick={onAvatarPictureChange}
                    defaultChecked={selectedAvatarPicture === index}
                  />
                </label>
              ))}
            </div>
            <div className="mx-8">
              <p className="mb-2">
                <b>名前をつけましょう。</b>
              </p>
              <input
                type="text"
                id="avatar_name"
                className="border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                value={avatarName}
                maxLength={AVATAR_NAME_MAX_LENGTH}
                onChange={onAvatarNameChange}
                checked
                required
              />
              <p className="text-[#bcada4] text-right mr-4 mt-2">
                {getAvatarNameLength(avatarName) + '/ 26'}
              </p>
            </div>
          </div>
          <div className="mb-8">
            <Button
              text={'保存'}
              padding={'px-12 py-1'}
              onClick={onSaveClick}
              isDisable={avatarName === ''}
            />
          </div>
          <div className="mb-8">
            <Button
              text={'保存せずに破棄'}
              buttonPattern={'cancel'}
              padding={'px-5 py-1'}
              onClick={onCancelClick}
            />
          </div>
        </div>
      </div>
      <Loading visible={isVisibleLoading} />
    </div>
  );
};

export default SaveAvatar;
