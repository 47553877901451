import React from 'react';

import Header from '../ui/Header';
import Button, { ButtonType } from '../ui/Button';
import Loading from '../ui/LoadingView';
import AvatarPromptList from '../ui/AvatarPromptList';
import { GetGoogleAuth } from '../../common/common';

import { ResponseType as aplr } from '../../common/types/AvatarPromptListResponse';
import { item as aplri } from '../../common/types/AvatarPromptListResponse';
import { GoogleAuth } from '../../store';
import { ResponseType as oalr } from '../../common/types/CreateOriginalAvatarListResponse';
import { createOriginalAvatarRequest } from '../../common/types/CreateOriginalAvatarListResponse';
import { ResponseType as oaucr } from '../../common/types/OriginalAvatarUpdateCountResponse';

import { axiosClient } from '../../utilities/AxiosConfig';
import { useNavigate } from 'react-router-dom';

import { createOriginalAvatarListStore } from '../../createOriginalAvatarListStore';

async function getAvatarPromptList(userInfo: GoogleAuth): Promise<aplr> {
  try {
    const response = await axiosClient.get('/user/prompt/list', {
      headers: {
        userid: userInfo.userId,
        logintype: '2',
      },
    });
    // 各プロンプトリストにisEnableを追加
    Object.keys(response.data).forEach((key: string) => {
      response.data[key] = response.data[key].map((prompt: string) => {
        return {
          prompt: prompt,
          isEnable: true,
        };
      });
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      personPromptList: [],
      personModifierPromptList: [],
      actionPromptList: [],
      placePromptList: [],
      attirePromptList: [],
    };
  }
}

async function getOriginalAvatar(
  userId: number,
  prompt: createOriginalAvatarRequest,
): Promise<oalr> {
  try {
    console.log('CreateAvatar getOriginalAvatar');
    const response = await axiosClient.post<oalr>(
      '/user/originalAvatar/list',
      {
        avatarKeyPerson: prompt.avatarKeyPerson,
        avatarKeyPersonModifier: prompt.avatarKeyPersonModifier,
        avatarKeyAction: prompt.avatarKeyAction,
        avatarKeyPlace: prompt.avatarKeyPlace,
        avatarKeyAttire: prompt.avatarKeyAttire,
      },
      {
        headers: {
          userid: userId,
          logintype: '2',
        },
        timeout: 30000,
      },
    );

    const originalAvatarList = response.data.originalAvatarList;

    return { originalAvatarList: originalAvatarList };
  } catch (error) {
    console.error(error);
    return { originalAvatarList: [] };
  }
}

async function originalAvatarUpdateCount(userId: number): Promise<oaucr> {
  try {
    console.log('CreateAvatar getOriginalAvatar');
    const response = await axiosClient.post<oaucr>(
      '/user/originalAvatar/updateCount',
      {
        calculationType: 2,
      },
      {
        headers: {
          userid: userId,
          logintype: '2',
        },
      },
    );

    const result = response.data.result;

    return { result: result };
  } catch (error) {
    console.error(error);
    return { result: false };
  }
}

const CreateAvatar = () => {
  const [avatarPromptList, setAvatarPromptList] = React.useState<aplr>({
    personPromptList: [],
    personModifierPromptList: [],
    actionPromptList: [],
    placePromptList: [],
    attirePromptList: [],
  });
  const [isDisableCreateButton, setIsDisableCreateButton] = React.useState<boolean>(true);
  const userInfo = GetGoogleAuth();
  const navigate = useNavigate();
  const [setCreateOriginalAvatarList] = createOriginalAvatarListStore((state) => [
    state.setCreateOriginalAvatarList,
  ]);
  const [setCreateOriginalAvatarRequest] = createOriginalAvatarListStore((state) => [
    state.setCreateOriginalAvatarRequest,
  ]);

  const [visible, setVisible] = React.useState<boolean>(false);

  // 初回レンダリング
  React.useEffect(() => {
    (async () => {
      // プロンプト一覧取得
      const promptList = await getAvatarPromptList(userInfo);
      setAvatarPromptList(promptList);
      setIsDisableCreateButton(false);
    })();
    // ESLintのWARNING無効
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // アバタータグ押下時のイベント
  const onAvatarPromptClick = (e: React.MouseEvent<HTMLElement>, aplrKey: keyof aplr) => {
    let newAvatarPromptList: aplr = { ...avatarPromptList };
    newAvatarPromptList[aplrKey].forEach((prompt) => {
      // クリックされたタグの状態を反転
      if (prompt.prompt === e.currentTarget.id) {
        prompt.isEnable = !prompt.isEnable;
      }
    });
    setAvatarPromptList(newAvatarPromptList);
  };

  // リセットボタン押下時のイベント
  const onResetTagClick = () => {
    let newAvatarPromptList: aplr = { ...avatarPromptList };
    // 全てのプロンプトのisEnableをtrueに変更
    (Object.keys(newAvatarPromptList) as (keyof aplr)[]).forEach((key) => {
      newAvatarPromptList[key].forEach((prompt: aplri) => {
        prompt.isEnable = true;
      });
    });
    setAvatarPromptList(newAvatarPromptList);
  };

  // プロンプトリスト表示用
  let promptListComponents: JSX.Element[] = [];
  (Object.keys(avatarPromptList) as (keyof aplr)[]).forEach((key, index) => {
    let componentName = '';
    switch (key) {
      case 'personPromptList':
        componentName = 'だれが？';
        break;
      case 'personModifierPromptList':
        componentName = 'どんな人（動物・もの）？';
        break;
      case 'actionPromptList':
        componentName = '何をしている？';
        break;
      case 'placePromptList':
        componentName = 'どこで？';
        break;
      case 'attirePromptList':
        componentName = '何を着て？';
        break;
    }
    promptListComponents.push(
      <div className="mb-4" key={index}>
        <p className="mb-2 font-bold">{componentName}</p>
        <div className="rounded-lg bg-white py-2 px-1">
          <AvatarPromptList
            list={avatarPromptList[key]}
            aplrKey={key}
            onClick={onAvatarPromptClick}
          />
        </div>
      </div>,
    );
  });

  // 作成ボタン押下時のイベント
  const onCreateButtonClick = async () => {
    const selectRandomPrompt = (promptList: aplri[]) => {
      const targetPrompt: string[] = [];
      promptList.forEach((prompt: aplri) => {
        // 活性状態のタグのみ追加
        if (prompt.isEnable) {
          targetPrompt.push(prompt.prompt);
        }
      });

      // 活性状態のタグがない場合
      if (targetPrompt.length === 0) return '';

      return targetPrompt[Math.floor(Math.random() * targetPrompt.length)];
    };

    const data: createOriginalAvatarRequest = {
      avatarKeyPerson: selectRandomPrompt(avatarPromptList['personPromptList']),
      avatarKeyPersonModifier: selectRandomPrompt(avatarPromptList['personModifierPromptList']),
      avatarKeyAction: selectRandomPrompt(avatarPromptList['actionPromptList']),
      avatarKeyPlace: selectRandomPrompt(avatarPromptList['placePromptList']),
      avatarKeyAttire: selectRandomPrompt(avatarPromptList['attirePromptList']),
    };

    // ローディング画面呼び出し
    setVisible(true);

    // Requestを保存
    setCreateOriginalAvatarRequest(data);

    // アバター作成API呼び出し
    const originalAvatarList = [];
    for (var i = 0; i < Number(process.env.REACT_APP_CREATE_AVATAR_COUNT!); i++) {
      const apiResult: oalr = await getOriginalAvatar(userInfo.userId, data);
      originalAvatarList.push(apiResult.originalAvatarList[0]);
    }

    setCreateOriginalAvatarList(originalAvatarList);

    await originalAvatarUpdateCount(userInfo.userId);

    // ローディング画面の削除
    setVisible(false);
    navigate('/SaveAvatar');
  };

  // リセットボタンのパラメータ
  const resetButton: ButtonType = {
    text: '最初の状態に戻す',
    rounded: 'rounded-md',
    buttonPattern: 'cancel',
    fontSize: 'text-[0.5rem]',
    padding: 'px-4 py-0.5',
    margin: 'mt-1',
    onClick: onResetTagClick,
  };

  return (
    <div className="bg-baseColor w-screen min-h-screen">
      <Header title={'アバターメーカー'} backFlag={true} />
      <div className="pt-[56px]">
        <div className="mx-4 mt-6 mb-2">
          <p className="text-left mb-1 font-sans">
            <b className="text-textColor text-sm">オリジナルアバター作成</b>
          </p>
          <div className="text-center text-textColor text-sm">
            <p className="mb-4">
              <b>
                Lungleアプリの利用履歴を分析して、
                <br />
                言葉を用意しました！
              </b>
            </p>
          </div>
          <div className="text-left text-textColor text-xs">
            <p className="mb-4">
              用意された中からランダムに言葉が選ばれ、オリジナルアバターを作成します。
            </p>
            <p className="mb-4">
              <b>
                表示されている言葉の中で、<u>画像生成に使用したくない</u>
                言葉をタップして選択を解除してください。
              </b>
            </p>
          </div>
          {promptListComponents}
          <div className="text-right">
            <Button {...resetButton} />
          </div>
        </div>
      </div>
      <div className="py-[28px]">
        <Button
          text={'作成！'}
          padding={'px-10 py-1'}
          onClick={onCreateButtonClick}
          isDisable={isDisableCreateButton}
        />
      </div>
      <Loading visible={visible} />
    </div>
  );
};
export default CreateAvatar;
