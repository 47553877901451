import React from 'react';
import Header from '../ui/Header';
import List from '../ui/List';
import NormalCard from '../ui/NormalCard';
import SpecialCard from '../ui/SpecialCard';
import { item } from '../../common/types/TitleListResponse';

import { ResponseType as tlrt } from '../../common/types/TitleListResponse';
import { item as tlrtItem } from '../../common/types/TitleListResponse';
import { ResponseType as urt } from '../../common/types/UserResponse';
import { useNavigate } from 'react-router-dom';
import { GetGoogleAuth } from '../../common/common';
import { axiosClient } from '../../utilities/AxiosConfig';

async function getTitleList(userId: number): Promise<tlrt> {
  try {
    // URL
    const response = await axiosClient.get('/title/list', {
      headers: {
        userid: userId,
        logintype: '2',
      },
    });

    const type1 = response.data.titleType1.map((item: tlrtItem) => {
      return {
        genreName: item.genreName,
        titleName: item.titleName,
        isActive: item.isActive,
        titlePicture: item.titlePicture === undefined ? '' : item.titlePicture,
        medalCount: item.medalCount === undefined ? 0 : item.medalCount,
        medalList: item.medalList === undefined ? [] : item.medalList,
      };
    });
    const type9 = response.data.titleType9.map((item: tlrtItem) => {
      return {
        genreName: item.genreName,
        titleName: item.titleName,
        isActive: item.isActive,
        titlePicture: item.titlePicture === undefined ? '' : item.titlePicture,
        medalCount: item.medalCount === undefined ? 0 : item.medalCount,
        medalList: item.medalList === undefined ? [] : item.medalList,
      };
    });
    return {
      titleType1: type1,
      titleType9: type9,
    };
  } catch (error) {
    console.error(error);
    return { titleType1: [], titleType9: [] };
  }
}

async function getUser(userId: number): Promise<urt> {
  try {
    const response = await axiosClient.get('/user', {
      headers: {
        userid: userId,
        logintype: '2',
      },
    });

    return {
      titlePicture: response.data.titlePicture,
      userName: response.data.userName,
      gender: response.data.gender,
      birthday: response.data.birthday,
      titleId: response.data.titleId,
      titleType: response.data.titleType,
      selectAreaId: response.data.selectAreaId,
      areaList: response.data.areaList,
    };
  } catch (error) {
    console.error(error);
    return {
      titlePicture: '',
      userName: '',
      gender: 0,
      birthday: new Date(),
      titleId: 0,
      titleType: 0,
      selectAreaId: 0,
      areaList: [],
    };
  }
}

const AvatarMaker: React.FC<{ list: Array<item> }> = ({ list }) => {
  const navigate = useNavigate();
  let count: number = 0;
  for (let i = 0; i < list.length; i++) {
    if (list[i]['isActive'] && list[i]['medalList'].length === list[i]['medalCount']) {
      count++;
    }
  }

  if (count >= 2) {
    return (
      <div className="mx-4 my-6">
        <p className="text-left mb-2 font-sans">
          <b className="text-textColor text-sm">オリジナルアバターメーカー</b>
        </p>
        <p className="text-left mb-6 text-textColor text-[0.5rem] block">
          <span>
            オリジナルアバターを作成できます。
            <br />
            どんなアバターがゲットできるかはお楽しみに！
            <br />
            取得したアバターは、アプリ版マイページでアイコンに変更ができます。
            <br /> オリジナルアバターメーカーページは
          </span>
          <span
            className="inline underline cursor-pointer"
            onClick={() => navigate('/OriginalAvatarList')}
          >
            こちら
          </span>
        </p>
      </div>
    );
  }
  return <div />;
};

const Title = () => {
  const navigate = useNavigate();
  const [titleList, setTitleList] = React.useState<tlrt>({ titleType1: [], titleType9: [] });
  const [user, setUser] = React.useState<urt>({
    titlePicture: '',
    userName: '',
    gender: 0,
    birthday: new Date(),
    titleId: 0,
    titleType: 0,
    selectAreaId: 0,
    areaList: [],
  });

  const info = GetGoogleAuth();

  // 作成時(初回レンダリング)
  React.useEffect(() => {
    (async () => {
      // ユーザー情報の取得
      const user = await getUser(info.userId);
      setUser(user);
      // 取得済みの称号を取得
      const titleLists = await getTitleList(info.userId);
      setTitleList(titleLists);
    })();
  }, [info.userId]);

  const styles = {
    div: 'mx-4 my-6',
    p: 'text-left mb-2 font-sans',
    b: 'text-textColor text-sm',
    p_sub: 'text-left mb-6 text-textColor text-[0.5rem] block',
  };

  const canOpenURL = () => {
    window.location.href = process.env.REACT_APP_SCHEME_NAME!;
  };

  return (
    <div className="bg-baseColor">
      <Header title={'獲得済み称号一覧'} logoutFlag={true} />
      <div className="pt-[56px]">
        <img
          className={
            'rounded-full mt-6 mb-6 mx-auto ' +
            (user.titleType === 10 ? 'border-[10px] border-avatarBorderColor' : '')
          }
          src={user.titlePicture}
          alt="logo"
          width={user.titleType === 10 ? '164' : '144'}
          height={user.titleType === 10 ? '164' : '144'}
        />
        <div className={styles.div}>
          <p className={styles.p}>
            <b className={styles.b}>アバターの変更</b>
          </p>
          <p className={styles.p_sub}>
            <span>アプリ版マイページでアバターを獲得した称号アイコンに変更できます！</span>
            <br />
            <span className="inline">※</span>
            <span className="inline underline cursor-pointer" onClick={() => canOpenURL()}>
              こちら
            </span>
            <span className="inline">をタップするとアプリ版が起動されます。</span>
          </p>
        </div>

        <AvatarMaker list={titleList.titleType9} />

        <div className={styles.div}>
          <p className={styles.p}>
            <b className={styles.b}>称号一覧</b>
          </p>
          <p className={styles.p_sub}>
            <span
              className="inline underline cursor-pointer"
              onClick={() => navigate('/TitleList')}
            >
              こちら
            </span>
            <span className="inline">
              のページから、Lungle称号一覧を確認できます。
              <br />
              各称号詳細を見ると、称号獲得条件がわかります！
            </span>
          </p>
        </div>
        <List title={'ノーマル称号'} explanation={'たくさん使うと昇格できます！'} />
        <NormalCard list={titleList.titleType1} />
        <List
          title={'スペシャル称号'}
          explanation={'ジャンルごとにお店のレビューをたくさん投稿すると\n獲得できるかも？'}
        />
        <SpecialCard list={titleList.titleType9} />
      </div>
      <div className="pb-[56px]" />
    </div>
  );
};

export default Title;
