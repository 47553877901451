import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetGoogleAuth } from '../common/common';
import { useTitleNameStore } from '../titleNameStore';
import { fetchAndActivate, getValue } from 'firebase/remote-config';
import { remoteConfig } from '../auth/authFirebase';

type Props = {
    children: React.ReactNode;
  };

export const Wrapper = (props: Props) => {
    const { children } = props;

    const location = useLocation();
    const navigate = useNavigate();
    const info = GetGoogleAuth();

    useEffect(() => {
        // TOP画面以外の必須チェック
        if (location.pathname !== '/') {
          if (info.userId === 0) {
            alert('必要なデータが取得されていないのでTOP画面から実行してください');
            navigate('/');
          }
        }

        fetchAndActivate(remoteConfig)
            .then(() => {
                const val = JSON.parse(
                    getValue(remoteConfig, process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_KEY!).asString(),
                );
                if (val.is_maintenance) {
                    console.log('メンテナンス');
                    navigate('/Maintenance');
                }
            })
            .catch((err) => {
                console.log(err);
            });

    }, []);

    return <>{children}</>;
}
