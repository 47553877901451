import { useAuthStore, GoogleAuth } from '../store';

export function GetGoogleAuth(): GoogleAuth {
  const [oauthIdToken, userId, provider] = useAuthStore((state) => [state.oauthIdToken, state.userId, state.provider]);
  return {
    oauthIdToken: oauthIdToken === undefined ? '' : oauthIdToken,
    userId: userId === undefined ? 0 : userId,
    provider: provider === undefined ? 0 : provider,
  };
}
