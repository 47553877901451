import React from 'react';
import Header from '../ui/Header';
import List from '../ui/List';

import { useTitleNameStore } from '../../titleNameStore';
import { ResponseType as tdr } from '../../common/types/TitleDetailResponse';
import { GetGoogleAuth } from '../../common/common';
import { axiosClient } from '../../utilities/AxiosConfig';

async function getTitleDetail(
  userId: number,
  titleName: string,
): Promise<tdr> {
  try {
    const response = await axiosClient.get<tdr>('/title/detail?titleName=' + encodeURI(titleName), {
      headers: {
        userid: userId,
        logintype: '2',
      },
    });
    return {
      genreName: response.data.genreName,
      titleName: response.data.titleName,
      titlePicture: response.data.titlePicture,
      titleDescription: response.data.titleDescription,
      preconditionDescription: response.data.preconditionDescription,
    };
  } catch (error) {
    console.error(error);
    return {
      genreName: '',
      titleName: '',
      titlePicture: '',
      titleDescription: '',
      preconditionDescription: '',
    };
  }
}

const TitleDetail = () => {
  const [titleDetailData, setTitleDetailData] = React.useState<tdr>({
    genreName: '',
    titleName: '',
    titlePicture: '',
    titleDescription: '',
    preconditionDescription: '',
  });

  const info = GetGoogleAuth();

  const [storeTitleName] = useTitleNameStore((state) => [state.titleName]);
  const titleName = storeTitleName === undefined ? '' : storeTitleName;

  React.useEffect(() => {
    (async () => {
      const titleDetailResponse = await getTitleDetail(info.userId, titleName);
      setTitleDetailData(titleDetailResponse);
    })();
  }, []);

  return (
    <div className="bg-baseColor w-screen h-screen">
      <Header title={'称号詳細'} backFlag={true} />
      <div className="pt-[56px]">
        <div className="mt-6 text-xs text-textColor flex justify-center">
          {titleDetailData.genreName}
        </div>
        <div className="mt-2 mb-1 text-base text-textColor] font-bold flex justify-center">
          {titleDetailData.titleName}
        </div>

        <img
          className="rounded-full mt-3 mb-5  mx-auto"
          src={titleDetailData.titlePicture}
          alt="logo"
          width="240"
          height="240"
        />

        <div className="text-wrapper w-full max-w-[320px] m-auto">
          <p className="mt-1 mx-4 text-left text-textColor whitespace-pre-wrap text-[0.5rem]">
            {titleDetailData.titleDescription}
          </p>
          <List title={'獲得条件'} explanation={titleDetailData.preconditionDescription} />
        </div>
      </div>
    </div>
  );
};

export default TitleDetail;
