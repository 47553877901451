import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

interface TitleIdState {
  titleId: undefined | number;
  initializeTitleId: () => void;
  setTitleId: (titleId: number) => void;
}

export const useTitleIdStore = create<TitleIdState>()(
  persist(
    (set) => ({
      titleId: undefined,
      setTitleId: (titleId: number) => {
        set({ titleId: titleId });
      },

      initializeTitleId: () => {
        set({ titleId: undefined });
      },
    }),
    {
      name: 'titleId',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
