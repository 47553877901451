import { useEffect, useState } from 'react';

import { onAuthStateChanged, User } from 'firebase/auth';

import { auth } from '../auth/authFirebase';
import { useAuthAction } from '../hooks/useAuth';
import loading_gif from '../images/animation_loading.gif';

type Props = {
  children: React.ReactNode;
};

export const FirebaseInit = (props: Props) => {
  const { children } = props;
  const { signInAction } = useAuthAction();

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (getAuthUser: User | null) => {
      // if (getAuthUser == null) signInAction();
      setLoading(false);
    });

    return unsubscribe;
  },[]);

  if (loading || auth.currentUser == null) return <div className="fixed top-0 left-0 w-screen h-screen"><div className="fixed bg-baseColor opacity-80 h-screen w-screen flex justify-center items-center"><img src={loading_gif} alt="ローディング" width="150" height="150" /></div></div>;

  return <>{children}</>;
};
