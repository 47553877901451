import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchAndActivate, getValue } from 'firebase/remote-config';
import { remoteConfig } from '../../auth/authFirebase';
import retry from '../../images/retry.png';

const Maintenance = () => {
  const navigate = useNavigate();
  const [timeStr, setTimeStr] = React.useState<string>('');

  useEffect(() => {
    fetchAndActivate(remoteConfig)
      .then(() => {
        console.log('Maintenance useEffect');
        const val = JSON.parse(
          getValue(remoteConfig, process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_KEY!).asString(),
        );
        console.log(val);
        if (!val.is_maintenance) {
          console.log('通常');
          navigate(-1);
        }

        var time = new Date(val.maintenance_finish_time);

        setTimeStr(
          time.getFullYear() +
            '年' +
            Number(time.getMonth() + 1) +
            '月' +
            time.getDate() +
            '日' +
            time.getHours().toString().padStart(2, '0') +
            ':' +
            time.getMinutes().toString().padStart(2, '0'),
        );
      })
      .catch((err) => {
        console.log(err);
      });
  });

  return (
    <div className="bg-baseColor w-screen h-screen">
      <div className="ma fixed left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-20">
        <div className="bg-baseColor mx-5 w-full shadow-boxShadow rounded-lg bg-white p-6">
          <h2 className="text-left font-bold text-xl">ただいまメンテナンス中です</h2>

          <p className="my-6 text-left text-lg">
            ご迷惑おかけしますが、メンテナンス後に再度アプリの起動をお願いします。
          </p>
          <p className="text-left text-lg">終了予定時刻 : </p>
          <p className="text-left text-lg">{timeStr}</p>

          <button
            className="mt-6"
            type="button"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img className="mx-auto" src={retry} alt="retry" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
