import { ResponseType as aplr } from '../../common/types/AvatarPromptListResponse';
import { item as aplri } from '../../common/types/AvatarPromptListResponse';

const AvatarPrompt = (prompt: {
  text: string;
  isEnable: boolean;
  aplrKey?: keyof aplr;
  onClick?: (e: React.MouseEvent<HTMLElement>, aplrKey: keyof aplr) => void;
}) => {
  const colorCss = prompt.isEnable ? ' bg-avatarTagColor' : ' bg-avatarTagDisableColor';
  let promptCss = ' rounded-lg text-xs px-4 py-1 mx-1 my-2';
  let onPromptClick = (e: React.MouseEvent<HTMLElement>) => {};

  // 活性/非活性制御を行うはprompt同士の間隔を調整
  if (prompt.aplrKey && prompt.onClick) {
    promptCss = 'rounded-xl text-[15px] h-[36px] px-4 mx-3 my-2';
    onPromptClick = (e: React.MouseEvent<HTMLElement>) => {
      prompt.onClick!(e, prompt.aplrKey!);
    };
  }

  return (
    <span
      id={prompt.text}
      className={'inline-flex items-center ' + promptCss + colorCss}
      onClick={onPromptClick}
    >
      {prompt.text}
    </span>
  );
};

const AvatarPromptList = (avatarPromptList: {
  list: aplri[] | string[];
  aplrKey?: keyof aplr;
  onClick?: (e: React.MouseEvent<HTMLElement>, aplrKey: keyof aplr) => void;
}) => {
  let promptList = avatarPromptList.list;

  // listがstring配列だった場合にaplri配列へ変換
  if (typeof promptList[0] === 'string') {
    promptList = (promptList as string[]).map((val: string) => {
      return {
        prompt: val,
        isEnable: true,
      };
    });
  }

  return (
    <div className="text-white text-left">
      {(promptList as aplri[]).map((item, index) =>
        item.prompt !== '' ? (
          <AvatarPrompt
            key={index}
            text={item.prompt}
            isEnable={item.isEnable}
            aplrKey={avatarPromptList.aplrKey}
            onClick={avatarPromptList.onClick}
          />
        ) : null,
      )}
    </div>
  );
};

export default AvatarPromptList;
