import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

import { item, createOriginalAvatarRequest } from './common/types/CreateOriginalAvatarListResponse';

interface CreateOriginalAvatarListState {
  createOriginalAvatarList: item[];
  createOriginalAvatarRequest: undefined | createOriginalAvatarRequest;
  initializeOriginalAvatarList: () => void;
  setCreateOriginalAvatarList: (items: item[]) => void;
  setCreateOriginalAvatarRequest: (request: createOriginalAvatarRequest) => void;
}

export const createOriginalAvatarListStore = create<CreateOriginalAvatarListState>()(
  persist(
    (set) => ({
      createOriginalAvatarList: [
        {
          image: '',
          seed: -1,
          prompt: '',
          negativePrompt: '',
        },
      ],
      createOriginalAvatarRequest: undefined,
      setCreateOriginalAvatarList: (createOriginalAvatarList: item[]) => {
        set({
          createOriginalAvatarList: createOriginalAvatarList,
        });
      },
      initializeOriginalAvatarList: () => {
        set({
          createOriginalAvatarList: undefined,
        });
      },
      setCreateOriginalAvatarRequest: (request: createOriginalAvatarRequest) => {
        set({
          createOriginalAvatarRequest: request,
        });
      },
    }),
    {
      name: 'createOriginalAvatarList',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
