import React from 'react';
import active_medal from '../../images/active_medal.png';
import inactive_medal from '../../images/inactive_medal.png';

const MedalImage: React.FC<{ src: string }> = ({ src }) => {
  return <img className="" src={src} alt="inactive_medal" width="17" height="24" />;
};
const Medal: React.FC<{ medalCount: number; medalList: number[] }> = ({
  medalCount,
  medalList,
}) => {
  let mL = [];
  for (let i = 0; i < 3; i++) {
    mL.push(
      <li key={i} className="inline-block">
        {/* ループ回数 < メダル数がTrueかFalseかで画像のパスを置換 */}
        <MedalImage src={i < medalList.length ? active_medal : inactive_medal} />
      </li>,
    );
  }
  return (
    <div className="mx-4">
      <ul className="flex justify-center mx-auto mb-1">{mL}</ul>
    </div>
  );
};

export default Medal;
