import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';


export type selectProvider = 0 | 1 | 2;
export type GoogleAuth = {
  oauthIdToken: string;
  userId: number;
  provider: selectProvider;
};

interface AuthState {
  oauthIdToken: undefined | string;
  userId: undefined | number;
  provider: undefined | selectProvider;
  logout: () => void;
  setGoogleAuth: (oauthIdToken: string, userId: number, selectProvider: selectProvider) => void;
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      userId: undefined,
      oauthIdToken: undefined,
      provider: 0,
      setGoogleAuth: (oauthIdToken: string, userId: number, provider: selectProvider) => {
        set({ oauthIdToken: oauthIdToken, userId: userId, provider: provider });
      },
      logout: () => {
        set({ oauthIdToken: undefined, userId: undefined, provider: 0 });
      },
    }),
    {
      name: 'auth',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
