import React from 'react';

import loading from '../../images/animation_loading.gif';

const Loading: React.FC<{ visible: boolean }> = ({ visible }) => {
  if (!visible) return null;

  return (
    <div className="fixed top-0 left-0 w-screen h-screen">
      <div className="fixed bg-baseColor opacity-80 h-screen w-screen flex justify-center items-center">
        <img src={loading} alt="ローディング" width="150" height="150" />
      </div>
    </div>
  );
};

export default Loading;
