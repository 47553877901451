import { signInWithRedirect, signOut } from 'firebase/auth';
import useSWR from 'swr';

import { auth, googleProvider, appleProvider } from '../auth/authFirebase';
import { GetGoogleAuth } from '../common/common';

export const useAuthAction = () => {
  const info = GetGoogleAuth();
  const signInAction = () => {
    console.log('signInAction');
    if (info.provider === 2) {
      signInWithRedirect(auth, appleProvider).catch((err) => {
        alert(err);
      });
    } else {
      signInWithRedirect(auth, googleProvider).catch((err) => {
        alert(err);
      });
    }
  };
  const singOutAction = () => {
    signOut(auth);
  };
  return { signInAction, singOutAction };
};

export const useAuthenticated = () => {
  const uid: string = auth.currentUser ? auth.currentUser.uid : '';

  const { data: idToken, isLoading: isLoadingIDToken } = useSWR('idToken', async () => {
    if (auth.currentUser === null) {
      return '';
    }
    const token = await auth.currentUser.getIdToken();
    return token;
  });

  return { idToken, isLoadingIDToken, uid };
};
