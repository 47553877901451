import React from 'react';

export type ResultsStateType = {
  title: string;
  explanation: string;
  button: string;
};

const Title: React.FC<{ title: string }> = (title) => {
  return (
    <p className="text-left mb-2 font-sans">
      <b className="text-textColor text-sm">{title.title}</b>
    </p>
  );
};

const Explanation: React.FC<{ explanation: string }> = (explanation) => {
  return (
    <p className="text-left mb-4 text-textColor text-[0.5rem] whitespace-pre-wrap">
      {explanation.explanation}
    </p>
  );
};

const List: React.FC<{ title: string; explanation: string }> = (list) => {
  return (
    <div className="mx-4 mt-6 mb-2">
      <Title title={list.title} />
      {list.explanation && <Explanation explanation={list.explanation} />}
    </div>
  );
};

export default List;
