import React from 'react';
import Header from '../ui/Header';
import List from '../ui/List';
import NormalCard from '../ui/NormalCard';
import SpecialCard from '../ui/SpecialCard';

import { ResponseType as tlrt } from '../../common/types/TitleListResponse';
import { GetGoogleAuth } from '../../common/common';
import { axiosClient } from '../../utilities/AxiosConfig';

async function getTitleList(oauthIdToken: string, userId: number): Promise<tlrt> {
  try {
    const response = await axiosClient.get<tlrt>('/user/allTitleList', {
      headers: {
        userid: userId,
        logintype: '2',
      },
    });
    const type1 = response.data.titleType1.map((item) => {
      return {
        genreName: item.genreName,
        titleName: item.titleName,
        isActive: item.isActive,
        titlePicture: item.titlePicture === undefined ? '' : item.titlePicture,
        medalCount: item.medalCount === undefined ? 0 : item.medalCount,
        medalList: item.medalList === undefined ? [] : item.medalList,
      };
    });
    const type9 = response.data.titleType9.map((item) => {
      return {
        genreName: item.genreName,
        titleName: item.titleName,
        isActive: item.isActive,
        titlePicture: item.titlePicture === undefined ? '' : item.titlePicture,
        medalCount: item.medalCount === undefined ? 0 : item.medalCount,
        medalList: item.medalList === undefined ? [] : item.medalList,
      };
    });
    return {
      titleType1: type1,
      titleType9: type9,
    };
  } catch (error) {
    console.error(error);
    return { titleType1: [], titleType9: [] };
  }
}

const TitleList = () => {
  const [titleList, setTitleList] = React.useState<tlrt>({ titleType1: [], titleType9: [] });

  const info = GetGoogleAuth();

  // 作成時(初回レンダリング)
  React.useEffect(() => {
    (async () => {
      // 取得済みの称号を取得
      const titleLists = await getTitleList(info.oauthIdToken, info.userId);
      setTitleList(titleLists);
    })();
  }, []);
  return (
    <div className="bg-baseColor">
      <Header title={'称号一覧'} backFlag={true} />
      <div className="pt-[56px]">
        <List
          title={'Lungle称号一覧'}
          explanation={
            'Lungleでは、一定の条件を満たすと称号を獲得することができます！\nここでは獲得できる称号の一覧がみれます\n各称号をタップすると、称号の説明と獲得条件がわかります！'
          }
        />
        <List title={'ノーマル称号'} explanation={''} />
        <NormalCard list={titleList.titleType1} />
        <List title={'スペシャル称号'} explanation={''} />
        <SpecialCard list={titleList.titleType9} />
      </div>
      <div className="pb-[56px]" />
    </div>
  );
};

export default TitleList;
