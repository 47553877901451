import React, { useEffect, ReactElement } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTitleNameStore } from '../titleNameStore';
import { GetGoogleAuth } from '../common/common';
import axios from 'axios';

import { remoteConfig } from '../auth/authFirebase';
import { fetchAndActivate, getValue } from 'firebase/remote-config';

export const axiosClient = axios.create({
  timeout: 3000,
  headers: {
    'Content-Type': 'application/json',
  },
  baseURL: process.env.REACT_APP_API_ENDPOINT!,
});

const ComponentWrapper = ({ children }: { children: ReactElement }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { initializeTitleName } = useTitleNameStore();

  const info = GetGoogleAuth();

  const [screenDisplayFlag, setScreenDisplayFlag] = React.useState<boolean>(false);

  useEffect(() => {
    // axios
    // リクエスト インターセプター
    const requestInterceptors = axiosClient.interceptors.request.use((config) => {
      return config;
    });

    // レスポンス インターセプター
    const responseInterceptor = axiosClient.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        switch (error.response?.status) {
          case 400:
          case 401:
            // エラーページへ
            navigate('/error');
            break;
          default:
            break;
        }
        return Promise.reject(error);
      },
    );

    // TOP画面以外の必須チェック
    if (location.pathname !== '/') {
      if (info.userId === 0) {
        alert('必要なデータが取得されていないのでTOP画面から実行してください');
        navigate('/');
      }
    }

    setScreenDisplayFlag(true);

    // 称号詳細画面以外で選択された称号のタイトルを削除する
    if (location.pathname !== '/TitleDetail') initializeTitleName();

    fetchAndActivate(remoteConfig)
      .then(() => {
        console.log('ComponentWrapper useEffect');
        const val = JSON.parse(
          getValue(remoteConfig, process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_KEY!).asString(),
        );
        console.log(val);
        if (val.is_maintenance) {
          console.log('メンテナンス');
          navigate('/Maintenance');
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // クリーンアップ
    return () => {
      axiosClient.interceptors.request.eject(requestInterceptors);
      axiosClient.interceptors.response.eject(responseInterceptor);
    };
  }, [initializeTitleName, location, info.userId, navigate]);

  return <>{screenDisplayFlag ? <>{children}</> : null}</>;
};

export default ComponentWrapper;
