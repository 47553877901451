import React from 'react';
import { item } from '../../common/types/OriginalAvatarListResponse';
import { useNavigate } from 'react-router-dom';

import { useTitleIdStore } from '../../originalAvatarIdStore';

const OriginalAvatarCard: React.FC<{
  list: item[];
}> = ({ list }) => {
  const navigate = useNavigate();
  const [setTitleId] = useTitleIdStore((state) => [state.setTitleId]);

  return (
    <div className="bg-baseColor">
      <div className="mx-4 grid grid-cols-3 gap-2">
        {list.map((choice, index) => (
          <div
            key={index}
            className="bg-cardColor text-center text-white rounded"
            onClick={() => {
              setTitleId(choice.titleId);
              navigate('/OriginalAvatarDetail');
            }}
          >
            <div className="text-[#4D3D32] my-1 mx-1 text-xs h-[32px] flex items-center justify-center">
              <b className="break-all">{choice.titleName}</b>
            </div>
            <div className="mx-4">
              <img
                className="rounded-full mx-auto mt-1 mb-2 border-[5px] border-avatarBorderColor"
                src={choice.titlePicture}
                alt="logo"
                width="74"
                height="74"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OriginalAvatarCard;
