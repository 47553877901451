import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const webPageTitle = process.env.REACT_APP_WEB_PAGE_TITLE!;
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <title>{webPageTitle}</title>
        {`
           /* <!-- Google Tag Manager --> */ 
        `}
        <script>
          {`
            (function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-TLZB9CV');
          `}
        </script>
        {`/* <!-- End Google Tag Manager --> */`}

        {`/* <!-- Google Tag Manager (noscript) --> */`}
        <noscript>
          {`${(
            <iframe
              src="https://www.googletagmanager.com/ns.html?id=GTM-TLZB9CV"
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}
            ></iframe>
          )}`}
        </noscript>
        {`/* <!-- End Google Tag Manager (noscript) --> */`}
      </Helmet>
      <App />
    </HelmetProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
