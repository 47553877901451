export type ButtonType = {
  text: string;
  rounded?: string;
  fontSize?: string;
  buttonSize?: string;
  buttonPattern?: string;
  padding?: string;
  margin?: string;
  isDisable?: boolean;
  onClick?: () => void;
};

const Button = (button: ButtonType) => {
  let buttonClass = {
    rounded: button.rounded ? button.rounded : 'rounded-lg',
    fontSize: button.fontSize ? button.fontSize : 'text-xs',
    buttonSize: button.buttonSize ? button.buttonSize : '',
    buttonPattern: '',
    padding: button.padding ? button.padding : 'px-3 py-1',
    margin: button.margin ? button.margin : '',
  };

  if (button.buttonPattern === 'cancel') {
    buttonClass['buttonPattern'] =
      'bg-white active:bg-gray-200 border border-cancelButtonBorderColor text-cancelButtonFontColor';
  } else {
    buttonClass['buttonPattern'] =
      'text-white ' +
      (button.isDisable
        ? 'bg-basicButtonDisabledColor'
        : 'bg-basicButtonColor active:bg-basicButtonActiveColor');
  }
  const className: string = Object.values(buttonClass).join(' ');
  return (
    <button
      type="button"
      className={className}
      onClick={button.onClick}
      disabled={button.isDisable}
    >
      {button.text}
    </button>
  );
};

export default Button;
