import React from 'react';
import { useNavigate } from 'react-router-dom';
import Back from '../../images/back.png';
import MeunIcon from '../../images/Menu.png';
import Close from '../../images/close.png';
import Meun from './Menu';
const Header: React.FC<{ title: string; backFlag?: boolean; logoutFlag?: boolean }> = (props) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const onClickOpen = () => {
    console.log('onClickOpen');
    setOpen(true);
  };

  const onClickClose = () => {
    console.log('onClickClose');
    setOpen(false);
  };

  return (
    <div className="bg-[#BCADA4] fixed">
      <ul className="w-screen h-14 flex justify-center items-center">
        <li className="w-1/6 h-full flex justify-center items-center">
          {props.backFlag ? (
            <span onClick={() => navigate(-1)} className="p-4">
              <img className={'mx-auto'} src={Back} alt="back" width="12" height="12" />
            </span>
          ) : null}
        </li>
        <li className="w-4/6 h-full text-lg text-white flex justify-center items-center">
          {props.title}
        </li>
        <li className="w-1/6 h-full flex justify-center items-center">
          <div className="flex">
            {!open && (
              <span
                onClick={() => {
                  onClickOpen();
                }}
                className="p-4"
              >
                <img className={'mx-auto'} src={MeunIcon} alt="logout" width="24" height="24" />
              </span>
            )}
            {open && (
              <span
                onClick={() => {
                  onClickClose();
                }}
                className="p-4"
              >
                <img className={'mx-auto'} src={Close} alt="logout" width="46" height="46" />
              </span>
            )}
          </div>
        </li>

        {open && <Meun />}
      </ul>
    </div>
  );
};

export default Header;
