import { AxiosConfig } from '../../../utilities/AxiosConfig';
import { FirebaseInit } from '../../../utilities/FirebaseInit';
import { Wrapper } from '../../../utilities/Wrapper';

type Props = {
  children: React.ReactNode;
};

export const ParentLayout = (props: Props) => {
  const { children } = props;
  return (
    <>
      <section>
        <main>
          <Wrapper>
            <FirebaseInit>
              <AxiosConfig>{children}</AxiosConfig>
            </FirebaseInit>
          </Wrapper>
        </main>
      </section>
    </>
  );
};

export default ParentLayout;
